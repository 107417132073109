import React from "react";
import { Text, Img, Heading } from "..";

const AboutUs = () => {
  return (
    <div
      id="about-us"
      className=" w-full flex lg:flex-row flex-col lg:gap-5 pt-10"
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-7">
        <Text
          size="5xl"
          as="p"
          className="!text-red-500  !font-poppins uppercase"
        >
          About us
        </Text>
        {/* <Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
          Discover Our Story: Innovating Finance, Empowering Futures
        </Heading> */}
        <Text
          as="p"
          size="2xl"
          className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify"
        >
          FinTech Asset Pro (FAP) is a pioneering Malaysian fintech platform
          revolutionizing Islamic finance and social finance through
          cutting-edge technology. Rooted in Shariah principles, we provide
          ethical financial solutions that prioritize social equity,
          environmental stewardship, and inclusive growth. By integrating AI
          technology and blockchain systems, FAP ensures transparency,
          efficiency, and compliance across all services, from asset-backed
          financing to socially driven investment portfolios.
        </Text>
      </div>
      <div className="flex justify-center md:w-[40%]">
        <Img
          src="images/logo.png"
          alt="imageone_one"
          className="rounded-[10px] w-[70%]"
        />
      </div>
    </div>
  );
};

export default AboutUs;
