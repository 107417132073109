import React from "react";
import { Text, Img } from ".."; // Sesuaikan jalur komponen jika diperlukan

const FlagshipProducts = () => {
  return (
    <div className="flex flex-col items-center justify-start w-full pt-10">
      <div className="flex flex-col flex-1 items-start justify-start gap-7">
        <div className="w-full flex flex-col items-start gap-3 px-5 lg:px-0">
          <Text
            size="5xl"
            as="p"
            className="!text-red-500 !font-poppins uppercase"
          >
            Flagship Products
          </Text>
        </div>

        {/* --- Bagian 1: AI Nexus --- */}
        <div
          id="flagship-product-ai-nexus"
          className="w-full flex flex-col lg:flex-row lg:gap-5 px-5 lg:px-0"
        >
          {/* Kiri (Teks) */}
          <div className="flex flex-col flex-1 items-start justify-start gap-7">
            <Text
              size="5xl"
              as="p"
              className="!font-poppins uppercase !text-[#C2A15C]"
            >
              AI Nexus
            </Text>
            <Text
              as="p"
              size="2xl"
              className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify"
            >
              <strong>The AI Nexus Platform</strong> is an innovative solution
              designed to modernize and streamline the securitization process.
              Leveraging cutting-edge technologies like artificial intelligence
              (AI), natural language processing (NLP), and big data analytics,
              the platform offers a seamless framework for securitizing assets,
              issuing financial instruments, and facilitating investments. It
              incorporates Maqasid Shariah principles, along with Environmental,
              Social, and Governance (ESG) criteria, and aligns with the United
              Nations Sustainable Development Goals (SDGs). These integrations
              ensure ethical, sustainable, and globally impactful financial
              practices.
            </Text>
          </div>

          {/* Kanan (Ilustrasi) */}
          <div className="flex justify-center items-center md:w-[40%]">
            <Img
              src="images/img_mision.png" // Ganti jalur gambar sesuai kebutuhan
              alt="Flagship Product Illustration"
              className="w-[60%] lg:w-[95%]"
            />
          </div>
        </div>

        {/* --- Bagian 2: SedeqahTech --- */}
        <div
          id="flagship-product-sedeqahtech"
          className="w-full flex flex-col lg:flex-row lg:gap-5 px-5 lg:px-0"
        >
          {/* (Teks) */}
          <div className="flex flex-col flex-1 items-start lg:items-end justify-start gap-7 lg:order-last">
            <Text
              size="5xl"
              as="p"
              className="!font-poppins uppercase !text-[#C2A15C]"
            >
              SedeqahTech
            </Text>
            <Text
              as="p"
              size="2xl"
              className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify"
            >
              <span>
                <a
                  href="https://sedeqahtech.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>SedeqahTech</strong>
                </a>
              </span>{" "}
              is a pioneering social finance platform designed to streamline
              Islamic philanthropy and community empowerment. It facilitates
              seamless contributions for Sedeqah, Zakat, Waqf, and Infaq,
              integrating both fiat and cryptocurrency options. With a mission
              to enhance accessibility and transparency, SedeqahTech offers a
              user-friendly gateway for individuals worldwide to fulfill their
              religious obligations while embracing the innovation of digital
              currencies.
            </Text>
          </div>

          {/* (Ilustrasi) */}
          <div className="flex justify-center items-center md:w-[40%]">
            <Img
              src="images/sedeqahtech.png"
              alt="sedeqahtech_logo"
              className="w-[60%] lg:w-[95%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlagshipProducts;
