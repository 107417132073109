import React from "react";
import { Text, Img, Heading } from "..";

const ResponsibleFinanceFramework = () => {
  return (
    <div
      id="responsible-finance"
      className="flex flex-col items-center justify-start w-full mt-10"
    >
      <div className="w-full flex flex-col lg:flex-row lg:gap-5">
        <div className="flex flex-col flex-1 items-start justify-start gap-7">
          <Text
            size="5xl"
            as="p"
            className="!text-red-500 !font-poppins uppercase"
          >
            Responsible Finance Framework
          </Text>

          <div className="flex flex-col gap-3">
            <Heading size="5xl" as="h2" className="!font-poppins">
              1. Maqasid Shariah
            </Heading>
            <Text
              as="p"
              size="2xl"
              className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify pl-5"
            >
              FAP’s operations align with the five objectives of Islamic law:
              preserving faith, life, intellect, wealth, and progeny. We
              prioritize fairness (Adl), transparency, and risk-sharing to
              foster economic justice.
            </Text>
          </div>

          <div className="flex flex-col gap-3">
            <Heading size="5xl" as="h2" className="!font-poppins">
              2. Environmental, Social, &amp; Governance (ESG)
            </Heading>
            <Text
              as="ul"
              size="2xl"
              className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify list-disc pl-9"
            >
              <li>
                {" "}
                <strong>Environmental:</strong> Green financing initiatives and
                carbon-neutral partnerships.
              </li>
              <li>
                {" "}
                <strong>Social:</strong> Financial literacy programs and
                inclusive access for underserved communities.
              </li>
              <li>
                {" "}
                <strong>Governance:</strong> Ethical AI governance and board
                diversity.
              </li>
            </Text>
          </div>

          {/* Sustainable Development Goals (SDGs) */}
          <div className="flex flex-col gap-3">
            <Heading size="5xl" as="h2" className="!font-poppins">
              3. Sustainable Development Goals (SDGs)
            </Heading>
            <Text
              as="p"
              size="2xl"
              className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify pl-5"
            >
              FAP contributes to UN SDGs through poverty alleviation (SDG 1),
              gender equality (SDG 5), and climate action (SDG 13) via
              ESG-aligned portfolios.
            </Text>
          </div>
        </div>

        {/* Bagian Kanan (Gambar) */}
        <div className="flex flex-col justify-center items-center md:w-[40%] gap-5">
          <Img
            src="images/responsible-finance-framework.webp"
            alt="img_mosque"
            className="w-[60%] lg:w-[95%] shadow-lg rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleFinanceFramework;
