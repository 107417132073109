import React from "react";
import { Text, Img, Heading } from "..";

const LeadershipTeam = () => {
  const leaders = [
    {
      name: "Roslan Ahmad",
      title: "The Leadership Managing Director",
      image: "images/en-roslan.webp",
      description:
        "Roslan Ahmad brings over three decades of expertise in financial services, with a strong focus on Islamic finance, asset securitization, and fintech innovation. His extensive experience in treasury, capital markets, and corporate finance has seen him hold key leadership positions at renowned institutions such as Cagamas Berhad, Maybank Group, and Amanie Group, where he was instrumental in developing award-winning Sukuk structures and advancing Islamic financial solutions. Additionally, he has provided strategic guidance to global financial organizations, including the World Bank, on Islamic financing frameworks. His ability to seamlessly integrate fintech with traditional financial systems positions him as a driving force at Fintech Asset Pro, championing the company’s mission to bridge conventional and digital finance through cutting-edge technology and strategic collaborations.",
    },
    {
      name: "Syaqeef Jamall",
      title: "The Innovator Executive Director",
      image: "images/syaqeef.webp",
      description:
        "Syaqeef Jamall is a seasoned technology and business strategist with over 15 years of experience in product design, technology innovation, and blockchain technology. With a strong foundation in project management and strategic planning, he has successfully led multiple company and blockchain-based programs across diverse industries, including digital marketing, media production, and healthcare. As a co-founder and senior executive in various tech-driven ventures, he has been instrumental in driving innovation, spearheading the development of Web3 solutions, and managing large-scale digital transformation projects. His expertise extends to AI technology, where he actively explores its potential to enhance fintech solutions. With deep knowledge of agile methodologies, business development, and Web3 community building, he is a vital addition to Fintech Asset Pro’s management team, ensuring the seamless integration of emerging technologies, including AI and blockchain, into the company’s fintech ecosystem.",
    },
  ];

  return (
    <div
      id="leadership-team"
      className="flex flex-col items-center justify-start w-full pt-10"
    >
      <div className="w-full flex flex-col items-center gap-8">
        <Text
          size="5xl"
          as="p"
          className="!text-red-500 !font-poppins uppercase"
        >
          Management Team
        </Text>
        <div className="flex flex-col gap-10 lg:gap-20 w-full max-w-7xl">
          {leaders.map((leader, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row items-center gap-10"
            >
              {/* Gambar profil */}
              <div className="flex-shrink-0 w-40 h-72 rounded-lg overflow-hidden shadow-lg">
                <Img
                  src={leader.image}
                  alt={leader.name}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Konten teks */}
              <div className="flex flex-col items-start gap-4 flex-1">
                <Text
                  as="p"
                  size="3xl"
                  className="!text-red-500 !font-poppins uppercase"
                >
                  {leader.title}
                </Text>
                <Heading size="4xl" as="h3" className="!font-poppins">
                  {leader.name}
                </Heading>
                <Text
                  as="p"
                  size="2xl"
                  className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify"
                >
                  {leader.description}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeadershipTeam;
