import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Text, Img, Heading } from "..";

const advisors = [
  {
    name: "Pn Nolita Abdullah",
    image: "images/nolita-abdullah.webp",
    description:
      "Nolita Binti Abdullah is a seasoned finance professional with extensive expertise in asset management, debt structuring, and operational strategy, particularly in the Islamic finance and securitization space...",
  },
  {
    name: "Saifuddin Abu Bakar",
    image: "images/saifuddin.webp",
    description:
      "Saifuddin Abu Bakar is a seasoned capital markets and corporate finance expert with over 20 years of leadership experience in investment banking, ESG-focused advisory, and sustainable finance solutions...",
  },
  {
    name: "Zulkifli Bin Hassan",
    image: "images/zulkifli.webp",
    description:
      "Zulkifli Bin Hassan is a highly experienced compliance and risk management specialist with over 20 years of expertise in financial markets, regulatory frameworks, and Shariah governance...",
  },
];

const BoardOfAdvisorsSlider = () => {
  return (
    <div
      id="board-of-advisors"
      className="flex flex-col items-center justify-start w-full pt-10"
    >
      {/* Title Section */}
      <Text
        size="5xl"
        as="p"
        className="!text-red-500 !font-poppins uppercase text-center mb-12"
      >
        Board of Advisors
      </Text>

      {/* Swiper Slider */}
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        spaceBetween={30}
        slidesPerView={1}
        className="w-full max-w-6xl px-5"
      >
        {advisors.map((advisor, index) => (
          <SwiperSlide
            key={index}
            className="flex flex-col lg:flex-row items-center gap-8 p-8 rounded-lg"
          >
            {/* Advisor Image */}
            <div className="flex-shrink-0 w-40 h-40 rounded-full overflow-hidden border-4 border-red-500">
              <Img
                src={advisor.image}
                alt={advisor.name}
                className="w-full h-full object-cover"
              />
            </div>

            {/* Advisor Content */}
            <div className="flex flex-col flex-1">
              <Heading size="4xl" as="h3" className="!font-poppins mb-4">
                {advisor.name}
              </Heading>
              <Text
                as="p"
                size="xl"
                className="!text-black-900 !font-poppins !font-normal !leading-relaxed text-justify"
              >
                {advisor.description}
              </Text>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BoardOfAdvisorsSlider;
