import React from "react";
import { Text, Img, Heading } from "..";

const WhatWeDo = () => {
  const whatWeDo = [
    {
      image: "images/img_securitisation.svg",
      title: "Asset Securitisation",
      desc: "Through knowledges and experiences of our Subject Matter Experts, we are able to explore opportunities to securitise and monetise some financial and non- financial assets",
    },
    {
      image: "images/img_tokenisation.svg",
      title: "Asset Tokenisation",
      desc: "Working together with Strategic Partners in Financial Industry including digital exchanges globally",
    },
    {
      image: "images/img_fund.svg",
      title: "Global Fund Collection",
      desc: "Acting as Facilitator to authorized Collection organizations, we will assist to establish a collection entity and digital system of channeling collections to the right beneficiaries.",
    },
    {
      image: "images/img_bm.svg",
      title: "Business Management",
      desc: "We have right resources to manage the required reporting.",
    },
  ];
  return (
    <div id="our-services" className="flex flex-col gap-10 pt-10">
      <div className="flex flex-col items-start justify-start gap-7">
        <Text
          size="5xl"
          as="p"
          className="!text-red-500 !font-poppins uppercase"
        >
          Our Services
        </Text>
        {/* <Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
					Unlocking Opportunities: <br />
					Tailored Solutions for Your Success
				</Heading> */}
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 md:gap-14 w-full">
        {whatWeDo.map((data, index) => (
          <div key={index} className="flex flex-col items-start justify-start ">
            <div className="flex flex-col items-center justify-start h-[100px] w-[100px] p-[31px] bg-yellow-800_87/20 rounded-[10px]">
              <Img
                src={data.image}
                alt="stats1oneone"
                className="h-[38px] w-[38px]"
              />
            </div>
            <Heading
              size="4xl"
              as="h2"
              className="!text-blue_gray-900 !font-poppins my-5"
            >
              {data.title}
            </Heading>
            <Text
              size="xl"
              as="p"
              className="!text-blue_gray-400 !font-poppins !leading-6 md:!leading-[30px]"
            >
              {data.desc}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeDo;
